import CaseStudySection from 'components/CaseStudy'
import FooterV2 from 'components/FooterV2/FooterV2'
import HeaderV2 from 'components/HeaderV2/HeaderV2'
import SignInOrDemo from 'components/SignInOrDemo/SignInOrDemo'
import { useAuth } from 'components/auth'
import { useTranslation } from 'next-i18next'
import Hero from './components/Hero'
import PartnerSection from './components/PartnerSection'
import PricingSection from './components/PricingSection'
import TrialSection from './components/TrialSection'
import WorkSection from './components/WorkSection'
import { PARTNERS_ADMIN } from './utils/constants'

const LandingPageComponent = ({ locale }) => {
  const { signInWithGoogleAccount, createDemoAccount } = useAuth()

  const { t } = useTranslation('landingpage-admin/partner')

  return (
    <div className="pt-[72px] md:pt-20 selection:bg-g400">
      <HeaderV2 locale={locale} />

      <Hero handleDemoClick={createDemoAccount} />
      <PartnerSection partners={PARTNERS_ADMIN} title={t('partner_title')} />
      <WorkSection />
      <CaseStudySection />
      <TrialSection />
      <PricingSection />
      <TrialSection />
      <SignInOrDemo
        handleSignIn={signInWithGoogleAccount}
        handleDemoClick={createDemoAccount}
      />
      <FooterV2 />
    </div>
  )
}

export default LandingPageComponent
